import React from 'react';
import { graphql } from 'gatsby';

import Container from '../components/layout/Container';
import Layout from '../components/layout/Layout';
import MetaData from '../components/seo/MetaData';
import SendYourRecipes from '../components/calltoaction/SendYourRecipes';
import { FaInstagram } from 'react-icons/fa';
import Colors from '../constants/Colors';
import { Data } from '../models/StoryblokModel';
import { AuthorModel } from '../models/AuthorModel';
import StoryBlokImageComponent from '../components/images/imagecomponents/StoryBlokImageComponent';
import ChefHeader from '../components/chefs/ChefHeader';

interface Props {    
  data: Data,
  pageContext: PageContext,
  location: any
}

interface PageContext{
  authorName: string
}

const AuthorTemplate: React.FunctionComponent<Props> = ({ data }) => {

  const author: AuthorModel = JSON.parse(data.allStoryblokEntry.edges[0].node.content);

  return (
    <Layout>
      <MetaData
        title={author.author_name}
        description={"Mød vores kokke og få mere information omkring inspirationen til alle opskrifterne."}
      />
      <div className="bg-white">
        <ChefHeader 
          author={author}
        />
      </div>
    </Layout>
  );
};

export const query = graphql`
query QueryAuthorByName($authorName: String){
  allStoryblokEntry (filter: {field_author_name_string: {eq: $authorName}, field_component: {eq: "AuthorPage"}}){
            edges {
              node {
                id
                name
                created_at
                uuid
                slug
                full_slug
                content
                field_author_name_string
              }
            }
          }
}`

const icon = {
  color: Colors.green,
  opacity: 1,
  marginRight: 15,
  cursor: 'pointer'
} as React.CSSProperties;

export default AuthorTemplate;