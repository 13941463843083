import * as React from 'react';
import Container from '../layout/Container';
import StoryBlokImageComponent from '../images/imagecomponents/StoryBlokImageComponent';
import { FaInstagram } from 'react-icons/fa';
import { AuthorModel } from '../../models/AuthorModel';

interface Props {    
    author: AuthorModel
}

const ChefHeader: React.FunctionComponent<Props> = ({ author }) => {
    return (
        <>
        <Container className="mt-16">
            <div className="grid grid-cols-1 md:grid-cols-2 mt-8">
              <div className="light-theme-background rounded-l-md p-8 vertical-center">
                <div>
                    <h3 className="mb-4">{author.author_name}</h3>
                    <hr className="w-48 mb-4 border-2" />
                    
                    <div>
                        <div className="inline-block px-1 py-1">
                            <h6 className="mt-0 w-40">Livret </h6>
                        </div>
                        <div className="inline-block px-1 py-1 tex text-sm">
                            <span>{author.author_favourite_dish}</span>
                        </div>
                        <br></br>
                        <div className="inline-block px-1 py-1">
                            <h6 className="mt-0 w-40">Ingrediens </h6>
                        </div>
                        <div className="inline-block px-1 py-1 text-sm">
                            <span>{author.author_favourite_ingredient}</span>
                        </div>
                        <br></br>
                        <div className="inline-block px-1 py-1 ">
                            <h6 className="mt-0 w-40">Favoritkøkken </h6>
                        </div>
                        <div className="inline-block px-1 py-1 text-sm">
                            <span>{author.author_favourite_cuisine}</span>
                        </div>
                        <br></br>
                        <div className="inline-block px-1 py-1">
                            <h6 className="mt-0 w-40">Kost </h6>
                        </div>
                        <div className="inline-block px-1 py-1 text-sm">
                            <span>{author.author_diet}</span>
                        </div>
                    </div>
                
                    <a href={author.author_instagram_url} target="_blank" rel="noopener noreferrer">
                        <button className="internal-button button-text py-2 px-4 rounded inline-flex items-center mt-4">
                            <FaInstagram
                                size={20}
                                className="pr-1 mr-1"
                            />
                            <span>Se Instagram</span>
                        </button>
                    </a>
                </div>
              </div>
              <div>
                <StoryBlokImageComponent 
                    src={author.author_image[0].filename}
                    alt={author.author_name}
                    dimensions="900x900"
                />
              </div>
            </div>
        </Container>
        <Container className="mt-12">
        {author.author_description.map((description, index) => 
          <div key={index}>
            <h3>{description.description_name}</h3>
            <p dangerouslySetInnerHTML={{__html:description.description_text}}></p>
          </div>
          )}
        </Container>
        </>
    );
}

export default ChefHeader;