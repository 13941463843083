import React from 'react'
import StoryblokImage from '../StoryblokImage';

interface Props {
    src: string;
    alt: string;
    dimensions: string;
    className?: string
}

const StoryBlokImageComponent: React.FunctionComponent<Props> = ({ src, alt, dimensions, className })=> {
    return (
        <StoryblokImage 
            src={src} 
            alt={alt} 
            dimensions={dimensions} 
            smartCropping={true} 
            _className={`w-full rounded-lg opacity-100 ${className}`}
        />
    );
}

export default StoryBlokImageComponent;